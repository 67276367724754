import {useStore} from '@/store/store'
import * as modifiers from '@/utilities/Modifiers'
import History from '@/utilities/History.js'
import * as styles from '@/styles/paperStyles.js'
import paper from 'paper'
import * as utils from '@/utilities/Utils.js'

export class LineCommand {
  store = useStore()

  constructor(curPath, wasAdded, prevPath){
    this.curPath = null
    this.wasAdded = null
    this.prevPath = null
    if (curPath) this.addCurPath(curPath, wasAdded)
    if (prevPath) this.addPrevPath(prevPath)
  }
  addPrevPath(prevPath){
    let prevPathData = JSON.parse(JSON.stringify(prevPath.data))
    let prevPathSegments = utils.shallowClone(prevPath.segments)
    this.prevPath = {
      start: new paper.Point(prevPathSegments[0].point.x, prevPathSegments[0].point.y),
      end: new paper.Point(prevPathSegments[1].point.x, prevPathSegments[1].point.y),
      data: prevPathData,
      id: prevPath._id
    }
  }
  addCurPath(curPath, wasAdded){
    let curPathData = JSON.parse(JSON.stringify(curPath.data))
    let curPathSegments = utils.shallowClone(curPath.segments)
    this.curPath = {
      start: new paper.Point(curPathSegments[0].point.x, curPathSegments[0].point.y),
      end: new paper.Point(curPathSegments[1].point.x, curPathSegments[1].point.y),
      id: curPath._id,
      data: curPathData,
    }
    this.wasAdded = wasAdded
  }
  undo(){ 
    if (this.prevPath){
      this.remove(this.curPath)
      this.add(this.prevPath)
    }
    else{
      if (this.wasAdded) this.remove(this.curPath)
      else this.add(this.curPath)
    }
  }
  redo(){ 
    if (this.prevPath){
      this.remove(this.prevPath)
      this.add(this.curPath)
    }
    else{
      if (this.wasAdded) this.add(this.curPath)
      else this.remove(this.curPath)
    }
  }
  add(path){
    let newPath = new paper.Path(styles.line())
    newPath._id = path.id
    newPath.add(path.start, path.end)
    newPath.data = path.data
    this.store.drawingLayer.addChild(newPath)

    this.store.tools.draw.addEndNode(newPath)
    
    if (newPath.data.end=='released'){
      let release = modifiers.drawReleaseIcon(newPath, 'end')
      this.store.releaseLayer.addChild(release)
    }
    if (newPath.data.start=='released'){
      let release = modifiers.drawReleaseIcon(newPath, 'start')
      this.store.releaseLayer.addChild(release)
    }
    if (newPath.data.frameProps.orientation == 0){
      newPath.dashArray=null
    }
    else {
      newPath.dashArray=[10,10]
    }
    let memberSizes = this.store.tools.draw.addMemberSizeText(newPath)
    this.store.memberSizesLayer.addChild(memberSizes)
    this.store.tools.draw.addDimensions(newPath)
  }
  remove(path){
    let pathToRemove = this.store.drawingLayer.children.find(child => child._id == path.id)
    if (pathToRemove) modifiers.remove(pathToRemove)
  }
}

export class SupportCommand {
  store = useStore()

  constructor(curSupport, wasAdded, prevSupport){
    this.curSupport = null
    this.wasAdded = null
    this.prevSupport = null
    if (curSupport) this.addCurSupport(curSupport, wasAdded)
    if (prevSupport) this.addPrevSupport(prevSupport)
  }
  addPrevSupport(prevSupport){
    this.prevSupport = {
      data: utils.deepClone(prevSupport.data),
      id: prevSupport._id
    }
  }
  addCurSupport(curSupport, wasAdded){
    this.curSupport = {
      data: utils.deepClone(curSupport.data),
      id: curSupport._id
    }
    this.wasAdded = wasAdded
  }
  undo(){ 
    if (this.prevSupport) {
      this.remove(this.curSupport)
      this.add(this.prevSupport)
    }
    else {
      if (this.wasAdded) this.remove(this.curSupport)
      else this.add(this.curSupport)
    }
  }
  redo(){ 
    if (this.prevSupport) {
      this.add(this.curSupport)
      this.remove(this.prevSupport)
    }
    else {
      if (this.wasAdded) this.add(this.curSupport)
      else this.remove(this.curSupport)
    }
  }
  add(support){
    var newSupportIcon = this.store.tools.supports.newSupportIcon(support.data.type)
    newSupportIcon._id = support.id
    this.store.tools.supports.scaleInitial(newSupportIcon)
    newSupportIcon.position = {x: support.data.location.x, y: support.data.location.y + newSupportIcon.bounds.height/2}
    newSupportIcon.data = support.data
    newSupportIcon.strokeColor = styles.supportIcon.strokeColor
    this.store.supportLayer.addChild(newSupportIcon)
  }
  remove(support){
    let supportToRemove = this.store.supportLayer.children.find(child => child._id == support.id)
    modifiers.remove(supportToRemove)
  }
}

export class LoadCommand {
  store = useStore()

  constructor(curLoad, wasAdded, prevLoad){
    this.curLoad = null
    this.wasAdded = null
    this.prevLoad = null
    if (curLoad) this.addCurLoad(curLoad, wasAdded)
    if (prevLoad) this.addPrevLoad(prevLoad)
  }
  addPrevLoad(prevLoad){
    this.prevLoad = {
      data: utils.deepClone(prevLoad.data),
      id: prevLoad._id
    }
  }
  addCurLoad(curLoad, wasAdded){
    this.curLoad = {
      data: utils.deepClone(curLoad.data),
      id: curLoad._id
    }
    this.wasAdded = wasAdded
  }
  undo(){
    if (this.prevLoad) {
      this.remove(this.curLoad)
      this.add(this.prevLoad)
    }
    else {
      if (this.wasAdded) this.remove(this.curLoad)
      else this.add(this.curLoad)
    }
  }
  redo(){
    if (this.prevLoad) {
      this.remove(this.prevLoad)
      this.add(this.curLoad)
    }
    else {
      if (this.wasAdded) this.add(this.curLoad)
      else this.remove(this.curLoad)
    }
  }
  async add(load){
    let newLoad
    if (load.data.type.includes('Line')){
      let assocLine = this.store.drawingLayer.children.find(line => 
        line._id == load.data.elementId)
      let isLineMass = load.data.type == 'Line Mass' ? true : false
      if (assocLine){
        newLoad = await this.store.tools.loads.scaleLineLoad(
          assocLine, 
          load.data.magnitude,
          load.data.dir,
          isLineMass
        )
        newLoad._id = load.id
        newLoad.data = load.data
        this.store.tools.loads.addLoadText(newLoad)
        this.store.loadLayer.addChild(newLoad)
      }
    }
    else {
      newLoad = this.store.tools.loads.scalePointLoad(
        load.data.location,
        load.data.magnitude,
        load.data.dir
      )
    }
  }
  remove(load){
    let loadToRemove = this.store.loadLayer.children.find(child => child._id == load.id)
    if (loadToRemove) modifiers.remove(loadToRemove)
  }
}
export class ReleaseCommand {
  store = useStore()
  constructor(elementId, position, wasAdded) {
    this.elementId = elementId
    this.position = position,
    this.wasAdded = wasAdded
  }
  undo() {
    if (this.wasAdded) this.remove()
    else this.add()
  }
  redo() {
    if (this.wasAdded) this.add()
    else this.remove()
  }
  add() {
    let line = this.store.drawingLayer.children.find(child => child._id == this.elementId)
    line.data[this.position] = 'released'
    let release = modifiers.drawReleaseIcon(line, this.position)
    this.store.releaseLayer.addChild(release)
  }
  remove() {
    let line = this.store.drawingLayer.children.find(child => child._id == this.elementId)
    line.data[this.position] = 'fixed'
    let releaseToRemove = this.store.releaseLayer.children.find(child => 
      child.data.elementId == this.elementId && child.data.end == this.position)
    releaseToRemove.remove()
  }
}

export class nodeMoveCommand{
  store = useStore()
  constructor(nodeBeforeMove, assocElements){
    this.nodeBeforeMove = null
    this.lineLoads = []
    this.supports = []
    this.pointLoads = []
    this.releases = []
    if (nodeBeforeMove) this.addNodeBeforeMove(nodeBeforeMove)
    if (assocElements) this.addAssocElements(assocElements)
  }
  undo() {
    this.moveLinePoint(this.nodeBeforeMove)
    this.redrawLineLoad()
    this.movePointLoad(this.nodeBeforeMove)
    this.moveSupport(this.nodeBeforeMove)
    this.moveRelease()
  }
  redo() {
    this.moveLinePoint(this.nodeAfterMove)
    this.redrawLineLoad()
    this.movePointLoad(this.nodeAfterMove)
    this.moveSupport(this.nodeAfterMove)
    this.moveRelease()
  }
  addAssocElements(elements){
    elements.forEach(element => {
      switch(element.data.layer){
        case 'support':
          this.supports.push({
            data: utils.deepClone(element.data),
            id: element._id
          })
          break;
        case 'load':
          if (element.data.type == 'Line Load'){
            let dataClone = {
              data: utils.deepClone(element.data),
              id: element._id
            }
            this.lineLoads.push(dataClone)
          }
          else {
            let dataClone = {
              data: utils.deepClone(element.data),
              id: element._id
            }
            this.pointLoads.push(dataClone)
          }
          break;
        case 'release':
          this.releases.push({
            data: utils.deepClone(element.data),
            id: element._id
          })
      }
    })
  }
  addNodeBeforeMove(node) {
    this.nodeBeforeMove = {
      data: utils.deepClone(node.data),
      id: node._id
    }
  }
  addNodeAfterMove(node){
    this.nodeAfterMove = {
      data: utils.deepClone(node.data),
      id: node._id
    }
  }
  redrawLineLoad(){
    this.lineLoads.forEach(async (loadInfo) => {
      let load = this.store.loadLayer.children.find(load => load._id == loadInfo.id)
      let loadText = utils.findAssociatedText(load)
      loadText.remove()
      load.remove()
      let loadDataCopy = utils.deepClone(loadInfo.data)
      let line = utils.findLineById(loadInfo.data.elementId)
      let isLineMass = load.data.type == 'Line Mass' ? true : false
      let newLoad = await this.store.tools.loads.scaleLineLoad(line, loadDataCopy.magnitude, loadDataCopy.dir, isLineMass)
      newLoad._id = loadInfo.id
      this.store.tools.loads.addLoadText(newLoad)
    })
  }
  moveLinePoint(node){
    let line = utils.findLineById(node.data.lineId)
    let lineText = utils.findAssociatedText(line)
    if (lineText) lineText.remove()
    let segmentIndex = node.data.lineSegmentIndex
    line.segments[segmentIndex].point = {x: node.data.location.x, y: node.data.location.y}
    let lineNodes = utils.findLineNodes(line)
    lineNodes.forEach(lineNode => {
      if (lineNode.data.lineSegmentIndex == segmentIndex){
        lineNode.data.location = node.data.location
        lineNode.position = node.data.location
      }
    })
    this.store.tools.draw.addMemberSizeText(line)
    line.data.frameProps.type = utils.getFrameType(line)
    let oldDimension = utils.getAssociatedDimension(line)
    if (oldDimension) oldDimension.remove()
    this.store.tools.draw.addDimensions(line)
  }
  movePointLoad(node){
    this.pointLoads.forEach(loadInfo => {
      let load = this.store.loadLayer.children.find(load => load._id == loadInfo.id)
      let loadText = utils.findAssociatedText(load)
      loadText.remove()
      let newPosition = {x: node.data.location.x, y: node.data.location.y}
      load.position = newPosition
      this.store.tools.loads.translate(load, loadInfo.data.dir)
      load.data.location = newPosition
      this.store.tools.loads.addLoadText(load)
    })
  }
  moveSupport(node){
    this.supports.forEach(supportInfo => {
      let support = this.store.supportLayer.children.find(support => support._id = supportInfo.id)
      console.log(support)
      support.position = {x: node.data.location.x, y: node.data.location.y+support.bounds.height/2}
      support.data.location = {x: node.data.location.x, y: node.data.location.y}
    })
  }
  moveRelease(){
    this.releases.forEach(releaseInfo => {
      let line = utils.findLineById(releaseInfo.data.elementId)
      let newPosition = modifiers.getReleasePosition(line, releaseInfo.data.end)
      let release = this.store.releaseLayer.children.find(release => release._id == releaseInfo.id)
      release.position = newPosition
    })
  }
}

export class BatchCommand{
  constructor(commands){
    this.commands = commands ? commands : []
  }
  add(command){
    if (Array.isArray(command)) command.forEach(command => this.commands.push(command))
    else this.commands.push(command)
  }
  async undo(){
    let lineCommands = []
    let otherCommands = []
    this.commands.forEach(command => {
      if (Array.isArray(command)){
        command.forEach(subCommand => {
          this.processCommand(subCommand, lineCommands, otherCommands)
        }) 
      }
      else {
        this.processCommand(command, lineCommands, otherCommands)
      }
    })
    let filteredLineCommands = this.filterCommands(lineCommands)
    filteredLineCommands.forEach(command => {
      command.undo()
    })
    otherCommands.forEach(command => command.undo())
  }
  redo(){
    let lineCommands = []
    let otherCommands = []
    this.commands.forEach(command => {
      if (Array.isArray(command)){
        command.forEach(subCommand => {
          this.processCommand(subCommand, lineCommands, otherCommands)
        }) 
      }
      else {
        this.processCommand(command, lineCommands, otherCommands)
      }
    })
    let filteredLineCommands = this.filterCommands(lineCommands)
    filteredLineCommands.forEach(command => {
      command.redo()
    })
    otherCommands.forEach(command => command.redo())
  }

  //Filter out any commands where the id is the same, but wasAdded values are opposite
  filterCommands(commands) {
    const map = new Map();
    commands.forEach(command => {
      const { curPath: { id }, wasAdded } = command;
      if (map.has(id)) {
        const existingCommand = map.get(id);
        if (existingCommand.wasAdded !== wasAdded) {
          map.delete(id);
        }
      } else {
        map.set(id, command);
      }
    });
    return Array.from(map.values());
  }
  processCommand(command, lineCommands, otherCommands) {
    if (command.hasOwnProperty('curPath')){
      lineCommands.push(command)
    }
    else {
      otherCommands.push(command)
    }
  }
}

export function removeCommandHelper(item){
  const store = useStore()
  switch (item.data.layer){
    case store.drawingLayer.name:
      return new LineCommand(item, false)
    case store.supportLayer.name:
      return new SupportCommand(item, false)
    case store.loadLayer.name:
      return new LoadCommand(item, false)
  }
}
