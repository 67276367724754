import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import {highlight, unhighlight, selectionControl} from '@/utilities/Modifiers.js'
import * as modifiers from '@/utilities/Modifiers.js'
import { defaultImperial, defaultMetric } from '@/constants/SteelShapes'
import { auth0Service } from '@/auth'
import { apiService } from '../plugins/apiPlugin'

export const useStore = defineStore('store', {
  state: () => ({
    promptPWAInstall: useStorage('promptPWAInstall', false),
    projectId: null,
    projectName: null,
    user: null,
    users: null,
    userPermissions: null,
    computeLog: null,
    onMobile: null,
    canvas: null,
    activeTool: 'select',
    subTool: 'select',
    grid: null,
    zoomLevel: 1,
    tools: [],
    analyze: null,
    selectedItems: [],
    tempSelectionItems: [],
    selectionComplete: true,
    selectedLoad: null,
    analysisView: [
      {deflection: true},
      {moment: false},
      {shear: false},
      {axial: false}
    ],
    pointLoad: 5,
    lineLoad: 5,
    deflectionScale: 10,
    momentScale: 10,
    axialScale: 10, 
    shearScale: 10,
    numberPickerValues:null,
    showValues: true,
    units: 'imperial',
    gridOn: true,
    gridSize: 1,
    gridUnitMetric: 'm',
    gridUnitImperial: 'ft',
    pointLoadMetric: 'kN',
    pointLoadImperial: 'kips',
    lineLoadMetric: 'kN',
    lineLoadImperial: 'kips',
    deflectionUnitMetric: 'm',
    deflectionUnitImperial: 'ft',
    resultantForceUnitMetric: 'kN',
    resultantForceUnitImperial: 'kips',
    resultsSigDigits: 4,
    gravity: false,
    loadApplication: 'value',
    frameSize: defaultImperial,
    applyByValue: true,
    mode: 1,
    frequency: '',
    period: '',
    snapToGrid: true,
    inputDistance: [0, 0],

    snackbarText: "",
    snackbar: false,
    snackbarTimeOut: null,
    
    imageFile: null,
    imageAction: null,
    imageOpacity: 1,
    calibrationMode:false,
    beamDegreesFromHorz: 0,
    columnDegreesFromVert: 0,
    pinchHammer: null,
    rotateHammer: null,

    copy: false,
    copiedData: null,
    startPastePoint: null,
    pastePoint: null,

    moving: false,
    moveStartPoint: null,
    
    copiedDataBBox: null,
    lineProps: null,
    canvasCenter: null,
    hintMessage: null
  }),
  getters: {
    gridState: (state) => {
      if (state.grid !== null){
        return state.grid.gridOn
      }
      else{
        return true
      }
    },
    getInputDistance: (state) => {
      if (state.units == 'imperial'){
        return Number(state.inputDistance[0])+Number(state.inputDistance[1]/12)
      }
      else {
        return state.inputDistance[0]
      }
    },
    getPointLoadUnit: (state) =>{
      if (state.units == 'imperial'){
        return state.pointLoadImperial
      }
      else return state.pointLoadMetric
    },
    getLineLoadUnit: (state) => {
      if (state.units == 'imperial'){
        return `${state.lineLoadImperial}/${state.getLengthUnit}`
      }
      else {
        return `${state.lineLoadMetric}/${state.getLengthUnit}`
      }
    },
    getDeflectionUnit: (state) => {
      if (state.units == 'imperial'){
        return state.deflectionUnitImperial
      }
      else {
        return state.deflectionUnitMetric
      }
    },
    getResultantForceUnit: (state) => {
      if (state.units == 'imperial'){
        return state.resultantForceUnitImperial
      }
      else {
        return state.resultantForceUnitMetric
      }
    },
    imageLayer: (state) => {
      return state.canvas.project.layers.image
    },
    imageBoundingRectLayer: (state) => {
      return state.canvas.project.layers.imageBoundingRect
    },
    imageHandlesLayer: (state) => {
      return state.canvas.project.layers.imageHandles
    },
    imageDimsLayer: (state) => {
      return state.canvas.project.layers.imageDims
    },
    dimensionLayer: (state) => {
      return state.canvas.project.layers.dimension
    },
    drawingLayer: (state) => {
      if (state.canvas)
        return state.canvas.project.layers.drawing
    },
    nodeLayer: (state) => {
      return state.canvas.project.layers.node
    },
    selectedLayer: (state) => {
      return state.canvas.project.layers.selection
    },
    gridLayer: (state) => {
      return state.canvas.project.layers.grid
    },
    loadLayer: (state) => {
      return state.canvas.project.layers.load
    },
    supportLayer: (state) => {
      return state.canvas.project.layers.support
    },
    memberSizesLayer: (state) => {
      return state.canvas.project.layers.memberSizes
    },
    deflectionLayer: (state) => {
      return state.canvas.project.layers.deflection
    },
    momentLayer: (state) => {
      return state.canvas.project.layers.moment
    },
    shearLayer: (state) => {
      return state.canvas.project.layers.shear
    },
    axialLayer: (state) => {
      return state.canvas.project.layers.axial
    },
    utilizationLayer: (state) => {
      return state.canvas.project.layers.utilization
    },
    modeLayer: (state) => {
      return state.canvas.project.layers.mode
    },
    reactionLayer: (state) => {
      return state.canvas.project.layers.reaction
    },
    releaseLayer: (state) => {
      return state.canvas.project.layers.release
    },
    loadValuesLayer: (state) => {
      return state.canvas.project.layers.loadValues
    },
    deflectionValuesLayer: (state) => {
      return state.canvas.project.layers.deflectionValues
    },
    momentValuesLayer: (state) => {
      return state.canvas.project.layers.momentValues
    },
    shearValuesLayer: (state) => {
      return state.canvas.project.layers.shearValues
    },
    axialValuesLayer: (state) => {
      return state.canvas.project.layers.axialValues
    },
    utilizationValuesLayer: (state) => {
      return state.canvas.project.layers.utilizationValues
    },
    analysisLayers: (state) => {
      var layers = {
        deflection: state.canvas.project.layers.deflection,
        moment: state.canvas.project.layers.moment,
        shear: state.canvas.project.layers.shear,
        axial: state.canvas.project.layers.axial,
        mode: state.canvas.project.layers.mode,
        utilization: state.canvas.project.layers.utilization
      }
      return layers
    },
    analysisValuesLayers: (state) => {
      var layers = {
        deflection: state.canvas.project.layers.deflectionValues,
        moment: state.canvas.project.layers.momentValues,
        shear: state.canvas.project.layers.shearValues,
        axial: state.canvas.project.layers.axialValues,
        utilization: state.canvas.project.layers.utilizationValues
      }
      return layers
    },
    analysisScales: (state) => {
      var scales = {
        deflection: state.deflectionScale,
        moment: state.momentScale/10,
        shear: state.shearScale/10,
        axial: state.axialScale/10
      }
      return scales
    },
    analyzeMode: (state) => {
      if (state.analyze)
        return state.analyze.analyzeMode
    },
    getLengthUnit: (state) => {
      if (state.units == 'metric'){
        return state.gridUnitMetric
      }
      else {
        return state.gridUnitImperial
      }
    },
    getLoadUnit: (state) => {
      if (state.units == 'metric'){
        return state.pointLoadMetric
      }
      else 
        return state.pointLoadImperial
    },
    getAllCanvasElements: (state) => {
      let allCanvasElements = []
      let types = []
      let lines = state.drawingLayer.children
      let supports = state.supportLayer.children
      let loads = state.loadLayer.children
      if (lines.length > 0) allCanvasElements.push(lines); types.push('lines')
      if (supports.length > 0) allCanvasElements.push(supports); types.push('supports')
      if (state.loadLayer.visible && loads.length > 0) allCanvasElements.push(loads); types.push('loads')
      allCanvasElements.flat()
      return {
        elements: allCanvasElements,
        types: types
      }
    },
    getterUser: (state) => {
      return state.user
    }
  },

  //Actions
  actions: {
    addToSelection(items, addToTemp = false){
      const [itemsToAdd, itemsToRemove, selectionItems] = selectionControl(items, this.tempSelectionItems)
      if (itemsToAdd.length> 0) {
        highlight(itemsToAdd)
      }
      if (itemsToRemove.length > 0) {
        unhighlight(itemsToRemove)
      }
      if (addToTemp) this.tempSelectionItems = selectionItems
      else selectionItems.forEach(item => this.selectedItems.push(item))
    },
    replaceInSelection(replacement){
      this.selectedItems.forEach((item, index) => {
        if (item._id == replacement._id){
          this.selectedItems[index] = replacement
          highlight(replacement)
        }
      })
    },
    setSelectedItem(items){
      const [itemsToAdd, itemsToRemove, selectionItems] = selectionControl(items, this.selectedItems)
      if (itemsToAdd.length> 0){
        highlight(itemsToAdd)
      }
      if (itemsToRemove.length > 0){
        unhighlight(itemsToRemove)
      }
      this.selectedItems = selectionItems
    },
    changeUnits(unit){
      if (unit && unit == this.units) return
      if (unit) this.units = unit
      else {
        if (this.units == 'metric') this.units = 'imperial'
        else this.units ='metric'
      }
      this.snackbarText = "Units updated"
      this.snackbar = true
      let defaultSize
      if (this.units == 'metric') defaultSize = defaultMetric
      else defaultSize = defaultImperial

      this.frameSize = defaultSize
    },
    changeTool(tool, subTool){
      this.tools[this.activeTool].deactivate(tool)
      this.tools[tool].activate(subTool)
      this.activeTool = tool
      this.subTool = subTool
    },
    changeBackgroundColor(color){
      let canvas = document.getElementById('canvas')
      if (color == 'dark'){
        canvas.style = 'background: rgba(115, 115, 115, 0.5); display: block;'
      }
      else canvas.style = 'background: rgba(255,255,255,0); display: block;'
    },
    deactivateTool(){
      this.tools[this.activeTool].deactivate()
      this.activeTool = 'select'
      this.subTool =this.tools.select.subTool
      this.tools.select.activate(this.subTool)
    },
    setLineLoadUnit(units){
      let unitsNotChanged = false
      if (units == 'lbsN'){
        if (this.lineLoadImperial == 'lbs') unitsNotChanged = true
        this.lineLoadImperial = 'lbs'
        this.lineLoadMetric = 'N'
      }
      else {
        if (this.lineLoadImperial == 'kips') unitsNotChanged = true
        this.lineLoadImperial = 'kips'
        this.lineLoadMetric = 'kN'
      }
      if (unitsNotChanged) return
      this.snackbarText = 'Line Load Units Updated'
      this.snackbar = true
    },
    setPointLoadUnit(units){
      let unitsNotChanged = false
      if (units == 'lbsN'){
        if (this.pointLoadImperial == 'lbs') unitsNotChanged = true
        this.pointLoadImperial = 'lbs'
        this.pointLoadMetric = 'N'
      }
      else {
        if (this.pointLoadImperial == 'kips') unitsNotChanged = true
        this.pointLoadImperial = 'kips'
        this.pointLoadMetric = 'kN'
      }
      if (unitsNotChanged) return
      this.snackbarText = 'Point Load Units Updated'
      this.snackbar = true
    },
    setDeflectionUnit(units){
      let unitsNotChanged = false
      if (units == 'incm'){
        if (this.deflectionUnitImperial == 'in') unitsNotChanged = true
        this.deflectionUnitImperial = 'in'
        this.deflectionUnitMetric = 'cm'
      }
      else {
        if (this.deflectionUnitImperial == 'ft') unitsNotChanged = true
        this.deflectionUnitMetric = 'm'
        this.deflectionUnitImperial = 'ft'
      }
      if (unitsNotChanged) return
      this.snackbarText = 'Deflection Units Updated'
      this.snackbar = true
    },
    setResultantForceUnit(units){
      let unitsNotChanged = false
      if (units == 'lbsN'){
        if (this.resultantForceUnitImperial == 'lbs') unitsNotChanged = true
        this.resultantForceUnitImperial = 'lbs'
        this.resultantForceUnitMetric = 'N'
      }
      else {
        if (this.resultantForceUnitImperial == 'kipskN') unitsNotChanged = true
        this.resultantForceUnitMetric = 'kips'
        this.resultantForceUnitImperial = 'kN'
      }
      if (unitsNotChanged) return
      this.snackbarText = 'Resultant Force Units Updated'
      this.snackbar = true
    },
    setShowValues(value){
      this.showValues = value
      if (this.showValues == false){
        for (const layer in this.analysisValuesLayers){
          this.analysisValuesLayers[layer].visible = false
        }
      }
      else{
        for (const layer in this.analysisValuesLayers){
          if (this.analysisLayers[layer].visible == true){
            this.analysisValuesLayers[layer].visible = true
            this.analysisValuesLayers[layer].bringToFront()
          }
        }
      }
    },
    toggleShowImageUnderlay(){
      this.imageLayer.visible = !this.imageLayer.visible
      if (this.imageLayer.visible) this.snackbarText = "Image Underlay Visible"
      else this.snackbarText = "Image Underlay Hidden"
      this.snackbar = true
    },
    toggleShowDimensions(){
      this.dimensionLayer.visible = !this.dimensionLayer.visible
      if (this.dimensionLayer.visible) this.snackbarText = "Dimensions Visible"
      else this.snackbarText = "Dimensions Hidden"
      this.snackbar = true
    },
    toggleShowNodes(){
      this.nodeLayer.visible = !this.nodeLayer.visible
      if (this.nodeLayer.visible) this.snackbarText = "Nodes Visible"
      else this.snackbarText = "Nodes Hidden"
      this.snackbar = true
    },
    toggleMemberSizes(){
      this.memberSizesLayer.visible = !this.memberSizesLayer.visible
      if (this.memberSizesLayer.visible) this.snackbarText = "Section Names Visible"
      else this.snackbarText = "Section Names Hidden"
      this.snackbar = true
    },
    toggleShowLoading(){
      this.loadLayer.visible = !this.loadLayer.visible
      if (this.loadLayer.visible) this.loadValuesLayer.visible = true
      else this.loadValuesLayer.visible = false
      if (this.loadLayer.visible) this.snackbarText = "Loads Visible"
      else this.snackbarText = "Loads Hidden"
      this.snackbar = true
    },
    toggleGravity(){
      this.gravity = !this.gravity
      this.snackbar = true
      if (this.gravity) this.snackbarText = "Gravity Turned On"
      else this.snackbarText = "Gravity Turned Off"
    },
    toggleGrid() {
      this.gridOn = !this.gridOn
      if (!this.gridOn) this.snapToGrid = false
      else this.snapToGrid = true
    },
    toggleSnapToGrid(){
      if (!this.gridOn) return
      this.snapToGrid = !this.snapToGrid
      if (this.snapToGrid) this.snackbarText = "Snap To Grid Turned On"
      else this.snackbarText = "Snap To Grid Turned Off"
      this.snackbar = true
    },
    toggleShowValues(){
      this.showValues = !this.showValues
      if (this.showValues == false){
        for (const layer in this.analysisValuesLayers){
          this.analysisValuesLayers[layer].visible = false
        }
        this.snackbarText = "Analysis Result Values Hidden"
      }
      else{
        for (const layer in this.analysisValuesLayers){
          if (this.analysisLayers[layer].visible == true){
            this.analysisValuesLayers[layer].visible = true
            this.analysisValuesLayers[layer].bringToFront()
          }
        }
        this.snackbarText = "Analysis Result Values Visible"
      }
      this.snackbar = true
    },
    toggleModeShape(){
      this.analysisLayers.mode.visible = !this.analysisLayers.mode.visible
      if (this.analysisLayers.mode.visible) {
        this.drawingLayer.visible = false
        this.nodeLayer.visible = false
      }
      else {
        this.drawingLayer.visible = true
        this.nodeLayer.visible = true
      }
    },
    toggleAnalysisLayerView(layer){
      layer.visible = !layer.visible
      if (layer.visible == true && this.showValues){
        this.analysisValuesLayers[layer.name].visible = true
      }
      else{
        this.analysisValuesLayers[layer.name].visible = false
      }
    },
    toggleShowReactions(){
      this.reactionLayer.visible = !this.reactionLayer.visible
      if (this.reactionLayer.visible) this.snackbarText = "Reactions Visible"
      else this.snackbarText = "Reactions Hidden"
      this.snackbar = true
    },
    async toggleAnalyzeMode(){
      if (!this.analyze.analyzeMode){
        let okayToRun = this.analyze.okayToRun()
        if (okayToRun){
          if (this.selectedItems.length > 0){
            modifiers.unhighlight(this.selectedItems)
            this.selectedItems = []
          }
          this.analyze.analyzeMode = true
          this.tools[this.activeTool].deactivate()
          let analysisSuccessful = await this.analyze.runAnalysis()
          return analysisSuccessful
        }
        else this.analyze.warning = okayToRun
      }
      else {
        this.analyze.deactivate()
        this.analyze.analyzeMode = false
        this.changeTool('select', this.tools.select.subTool)
      }
    },
    async getUser() {
      if (this.user) return this.user
      else {
        let auth0User = auth0Service.user.value
        const user = await apiService({
          url: "/api/user/_user/get-user",
          method: "POST",
          data: {
            userId: auth0User.sub,
          }
        })
        if (user.data){
          this.user = user.data
          return user.data
        }
      }
    },
    async getComputeLog(){
      if (this.computeLog) return this.computeLog
      else {
        let computeLog
        if (!this.user) await this.getUser()
        computeLog = await apiService({
          url: "/api/computes/_computes/get-compute-by-user",
          method: "POST",
          data: {
            auth0User: auth0Service.user.value,
          }
        }) 
        if (computeLog.data == null) { //Create compute log if null
          computeLog = await apiService({
            url: "/api/computes/_computes/create-compute",
            method: "POST",
            data: {
              auth0User: auth0Service.user.value,
            }
          })
        }
        this.computeLog = computeLog.data
        return computeLog.data
      }
    },
    setUser(user) {
      this.user = user;
    },
    setComputeLog(log){
      this.computeLog = log
    },
    setUsers(users){
      this.users = users
    },
    async setUserAndPermission(projectId, userEmail){
      try{
        let projectPermissions = await apiService({
          url: "/api/permission/_permission/get-permission-by-projectId",
          method: "POST",
          data: {
            projectId: projectId,
          }
        })
        let hasAccess = false
        projectPermissions.data.forEach(permission => {
          if (permission.email.toLowerCase() == userEmail.toLowerCase()){
            this.setUsers(projectPermissions.data)
            this.userPermissions = permission
            hasAccess = true
          }
        })
        if (hasAccess) return true
      }
      catch (error){
        console.log('Error setting user and permissions:', error)
        return false
      }
    }
  },
})
