import { auth0Service } from '@/auth'
import {useStore} from '@/store/store'
import { apiService } from '../plugins/apiPlugin';
import { H } from 'highlight.run';


function pipeline(to, from, final, guards) {
  let idx = -1;
  let nxt = (args) => {
    if (typeof args !== 'undefined') return final(args);
    if (idx === guards.length - 1) return final();
    idx++;
    return guards[idx](to, from, nxt);
  };
  return nxt();
}


function authGuard(to, from, next) {
  const store = useStore()
  auth0Service.getAccessTokenSilently().then(async (token) => {
    H.identify(auth0Service.user.value.email)
    await createComputeLog(auth0Service.user.value)
    if (auth0Service.isAuthenticated.value && auth0Service.user.value.email_verified){
      if (!store.user){
        let auth0User = auth0Service.user.value
        const user = await apiService({
          url: "/api/user/_user/get-user",
          method: "POST",
          data: {
            userId: auth0User.sub,
          }
        })
        if (user.data) store.setUser(user.data)
      }
      return next()
    }
    else {
      store.setUser(null);
      return next('/401')
    }
  }).catch(error => {
    store.setUser(null);
    return next('/')
  })
}

function canvasGuard(to, from, next){
  const store = useStore()
  auth0Service.getAccessTokenSilently().then(async (token) => {
    if (auth0Service.isAuthenticated.value){
      if (auth0Service.user.value.email_verified){
        if (!store.projectId) store.projectId = to.params.id
        if (store.projectId.includes('template')){
          return next()
        }
        let success = await store.setUserAndPermission(store.projectId, auth0Service.user.value.email)
        if (success) return next()
        else return next('401')
      }
      else return next('/401')
    }
    else return next('/401')
  }).catch(error => {
    return next('/401')
  })
}

function redirectToDashboard(to, from, next) {
  const store = useStore()
  auth0Service.getAccessTokenSilently().then(async (token) => {
    if (auth0Service.isAuthenticated.value){
      let auth0User = auth0Service.user.value
      const user = await apiService({
        url: "/api/user/_user/get-user",
        method: "POST",
        data: {
          userId: auth0User.sub,
        }
      })
      //First time login, Create New User Object
      if(!user.data){
        try {
          let user = await apiService({
            url: "/api/user/_user/create-user",
            method: "POST",
            data: {
              auth0User: auth0User
            }
          })
          store.setUser(user.data)
          await createComputeLog(auth0User)
          if (auth0User.email.toLowerCase().includes('thorntontomasetti')){
            return next('/dashboard')
          }
          else{ 
            //TODO SWITCH THIS TO PLANS AFTER STRIPE INTEGRATION!
            return next('/dashboard')
            //return next('/plans')
          }
        }catch (error){
          console.log(error)
          return next('/401')
        }
      } 
      else {
        await createComputeLog(auth0User)
        //Redirect to plans page if none selected
        store.setUser(user.data)
        let plan = user.data.plan
        if (plan != null){
          return next('/dashboard')
        }
        else {
          //TODO SWITCH THIS TO PLANS AFTER STRIPE INTEGRATION!
          return next('/dashboard')
          //return next('/plans')
        }
      }
    }
    else {
      console.log('not authenticated', this.auth0Service)
      return next('/401')
    }
  }).catch(error => {
    console.log(error)
    store.setUser(null)
    return next()
  })
}

async function createComputeLog(auth0User){
  const store = useStore()
  let computeLog
  try{
    let computes = await apiService({
      url: "/api/computes/_computes/get-compute-by-user",
      method: "POST",
      data: {
        auth0User: auth0User,
      }
    })
    computeLog = computes.data
    if (!computes.data){
      let computes = await apiService({
        url: "/api/computes/_computes/create-compute",
        method: "POST",
        data: {
          auth0User: auth0User,
        }
      })
      computeLog = computes.data
    } 
    else {
      store.setComputeLog(computeLog)
    }
  } catch(error){
    console.log('error', error)
  } 
}

export default {
  pipeline,
  authGuard,
  canvasGuard,
  redirectToDashboard
};